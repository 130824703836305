@tailwind base;

@tailwind components;

@tailwind utilities;

@font-face {
    font-family: 'IRANSansX';
    src: local('IRANSansX'), url(./assets/fonts/woff/IRANSansX-Regular.woff) format('woff');
}

@layer base {
    body {
        direction: rtl;
        -moz-font-feature-settings: "ss02";
        -webkit-font-feature-settings: "ss02";
        font-feature-settings: "ss02";
        color: #010101;
        background-color: #F6F7FA;
    }
}

input[type="tel"]:-moz-placeholder {
    text-align: right;
}
input[type="tel"]:-ms-input-placeholder {
    text-align: right;
}
input[type="tel"]::-webkit-input-placeholder {
    text-align: right;
}
*:focus {
    outline: none;
}

.flickity-button:focus {
    box-shadow: none !important;
}

.button-shadow {
    box-shadow: 0 10px 23.8px 1.2px rgb(62 205 219 / 30%);
}

.dot {
    background: #3ecddb !important;
}

.ltr-input {
    direction: ltr !important;
}

.printing-config {
    -webkit-print-color-adjust: exact !important;  
    color-adjust: exact !important;                 
    print-color-adjust: exact !important;        
}
@page {
    size: A4;
    margin: 0;
}
@media print {
    html, body {
        width: 210mm;
        height: 297mm;        
    }
    .page {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }
}

@layer components {
    .box-shadow {
        box-shadow: 2px 5px 16px 5px #e8e8e8;
    }

    .selected-tab {
        background-color: #fff;
        border-radius: 5px;
    }
}

.Toastify__toast {
    font-family: IRANSansX !important;
    width: 80%;
    margin: 0.5rem 0.5rem 0 0;
}

.MuiList-root {
    max-height: 300px;
}

.leaflet-control-zoom {
    display: none;
}

.tooltip-cl {
    width: fit-content !important;
}